.navbar {
  box-shadow:  0 0 20px #a9b0b2,
  0 0 20px #ffffff;
  z-index: 9000;

  .navbar-brand {
    max-width: 30%;

    .navbar-brand-logo {
      max-width: 100%;
    }
  }

  .navbar-center {
    width: 100%;
    text-align: center;
    margin: auto;
  }
}

.top-padding-for-navbar {
  padding-top: 70px;
}