.footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  padding-top: 20px;
  padding-bottom: 20px;
  height: 160px;
  color: #898989;
  background-color: #110f0e;

  b, strong {
    font-weight: bolder;
    font-family: 'Montserrat';
  }

  a {
    color: #a6a6a6;
    text-decoration: none !important;
    border-bottom: 1px solid transparent;
    transition: .1s;

    &:hover,
    &:focus,
    &:active
    {
      border-bottom: 1px solid silver;
      text-decoration: none !important;
      color: #fff;
      text-shadow: 0px 1px 1px rgba(248, 239, 204, 0.86);
      transition: .1s;
    }
  }

  .footer_logo {
    max-width: 180px;

    &:hover {
      animation: pulse 1s linear;
    }
  }

  @media only screen and (max-width: 768px) {
    height: auto !important;
    min-height: auto !important;
    padding-bottom: 70px;
  }
}