.card {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
  transition: all 1s ease-out;

  h2.author_name {
    font-size: 20px;
  }

  a:not(.btn):not(.list-group-item) {
    color: #0a0a0a;
    text-decoration: none !important;
    border-bottom: 1px solid transparent;

    &:hover,
    &:active,
    &:focus {
      color: black;
      border-bottom: 1px solid gray;
    }
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  }
}