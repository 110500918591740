.overlay_link {
  font-size: 24px;
  font-weight: bolder;

  position: absolute;
  bottom: 0;
  right: 0;

  background-color: rgba(0,0,0, .7);
  color: white;
}
