html {
  position: relative;
  min-height: 100vh;
  overflow-y: scroll;
}

body {
  min-width: 300px;
  padding-top: 70px;
  padding-bottom: 550px;
  margin-bottom:550px; /* Margin bottom by footer height */
  min-height: 80vh;

  @media only screen and (max-width: 768px) {
    padding-bottom: 950px;
    margin-bottom: 950px; /* Margin bottom by footer height */
  }
}