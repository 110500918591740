@keyframes colorchange
{
  0%   {background: #fffce8;}
  25%  {background: #fef9ff;}
  50%  {background: #fff3c6;}
  75%  {background: #fffdf5;}
  100% {background: #fbfff9;}
}

.glam {
  animation: colorchange 50s;
}
