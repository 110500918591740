@import "custom_colors.scss";
@import "animations.scss";
@import "utils.scss";


@import "footer.scss";
@import "header.scss";
@import "index.scss";
@import "intranet.scss";
@import "cards.scss";
@import "grid.scss";
@import "body.scss";

@import "~bootstrap/scss/bootstrap";
@import '~aos/dist/aos.css';
@import "~@fortawesome/fontawesome-free/scss/fontawesome";

